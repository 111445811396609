





















































































































































.managerArea {
  display: flex;
  margin-bottom: 10px;
  .managerArr {
    display: flex;
    .delteBtn {
      margin-left: 10px;
    }
  }

  .buttomAd {
    margin-left: 10px;
  }
}
